import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Proctoring from '@/components/Proctoring'
import NavbarNew from "@/components/NavbarNew.vue";

export default [
  {
    path: '/enter/:token',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/Index')
    },
  },
  {
    name: 'identification',
    path: '/identification',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/Identification')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'about',
    path: '/about',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Home/About')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'question',
    path: '/question/:id',
    components: {
      header: Navbar,
      proctoring: Proctoring,
      default: () => import('@/views/Test/Index')
    },
    meta: { requiresAuth: true }
  },
  {
    name: 'completed',
    path: '/completed',
    components: {
      header: Navbar,
      default: () => import('@/views/Completed')
    },
  },
  {
    name: 'results',
    path: '/results',
    components: {
      header: Navbar,
      default: () => import('@/views/Results')
    },
  },
  {
    name: 'question-preview',
    path: '/question-preview/:id',
    components: {
      default: () => import('@/views/PreviewQuestion')
    }
  },
  {
    name: 'rules',
    path: '/rules/:token',
    components: {
      header: NavbarNew,
      default: () => import('@/views/Home/Rules')
    },
    // meta: { requiresAuth: true }
  },
  {
    name: 'about-subject',
    path: '/about-subject/:id',
    components: {
      header: NavbarNew,
      proctoring: Proctoring,
      default: () => import('@/views/Home/AboutSubject')
    },
    // meta: { requiresAuth: true }
  },
  {
    name: 'test-page',
    path: '/test-page/:id',
    components: {
      header: NavbarNew,
      proctoring: Proctoring,
      default: () => import('@/views/Home/TestPage')
    },
    // meta: { requiresAuth: true }
  },
  {
    name: 'about-test',
    path: '/about-test',
    components: {
      header: NavbarNew,
      default: () => import('@/views/Home/AboutTest')
    },
    // meta: { requiresAuth: true }
  },
  {
    path: '/test',
    components: {
      header: NavbarNew,
      proctoring: Proctoring,
      default: () => import('@/views/Test')
    },
    // meta: { requiresAuth: true }
  },
  {
    name: 'test-results',
    path: '/test-results',
    redirect: '/completed',
    components: {
      header: NavbarNew,
      default: () => import('@/views/TestResults')
    },
    // meta: { requiresAuth: true }
  },
  {
    name: 'not-found',
    path: '*',
    components: {
      header: Navbar,
      default: Empty
    }
  }
]
