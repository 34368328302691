import * as API from '@/api'

const state = {
  permissionWebCam: false,
  permissionAudio: false,
  permissionWebContents: false,
  webcamSize: 0,
  streamCamera: '',
  streamAudio: '',
  lowNoiseLevel: 97,
  highNoiseLevel : 128,
  photo: localStorage.getItem("savedPhoto") || null,
  violations: Number(localStorage.getItem('violationsNumber')) || 15,
}

const getters = {
  getPhoto: (state) => state.photo,
}

const mutations = {
  updatePermissionAudio(state, data) {
    state.permissionAudio = data;
  },
  updatePermissionWebCam(state, data) {
    state.permissionWebCam = data;
  },
  updatePermissionWebContents(state, data) {
    state.permissionWebContents = data;
  },
  updateWebcamSize(state, data) {
    state.webcamSize = data;
  },
  updateStreamCamera(state, data) {
    state.streamCamera = data;
  },
  updateStreamAudio(state, data) {
    state.streamAudio = data;
  },
  setPhoto(state, photo) {
    state.photo = photo;
    localStorage.setItem("savedPhoto", photo);
  },
  updateViolations(state, num) {
    state.violations = num;
  }
}


export default {
  state,
  getters,
  mutations,
}