<template>
  <div>
    <nav class="navbar-block">
      <div class="container h-100 d-flex align-items-center">
        <img width="61" height="38" src="/images/nav-logo.svg" alt="logo"/>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'NavbarNew',
  data () {
    return {
      // userQuizId:localStorage.getItem('user_quiz_id'),
      logged: false,
      collapsed: true,
      project_id: null
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === '' || localStorage.getItem('token') === null);
    if (localStorage.getItem('quiz')){
      const { project_id } = JSON.parse(localStorage.getItem('quiz'));
      this.project_id = project_id
    }
  },
  methods: {

    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway () {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
