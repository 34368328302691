<template>
  <div>
<!--    <draggable>-->
<!--      <el-select-->
<!--          v-model="value2"-->
<!--          class="m-2"-->
<!--          placeholder="Select"-->
<!--          size="small"-->
<!--          style="width: 100px"-->
<!--          @change="updateWebCamSize(value2)"-->
<!--      >-->
<!--        <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--        />-->
<!--      </el-select>-->

<!--      <div id="webcam" class="hide position-relative" style="height: fit-content;">-->
<!--        <video autoplay="" id="video-player" class="proctoring-video">-->
<!--        </video>-->
<!--        <div class="microphone-area">-->
<!--          <div class="soundbar-block" v-if="getAudio">-->
<!--            <div class="soundbar" style="background-color: transparent" :class="{ disabled: !getAudio }">-->
<!--              <div-->
<!--                  v-for="(bar, index) in bars"-->
<!--                  :key="index"-->
<!--                  class="bar"-->
<!--                  :style="{ height: bar.height + '%', backgroundColor: bar.color }"-->
<!--              ></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div id="displayMedia" class="hide" style="display: none">-->
<!--        <video autoplay="" width="400" height="300" id="display-media-player"></video>-->
<!--        <img src="" alt="" width="640" height="400" id="display-media-screen">-->
<!--      </div>-->
<!--    </draggable>-->
    <div style="display: none">
      <div id="webcam" class="hide">
        <video autoplay="" id="video-player"></video>
      </div>
      <div id="displayMedia" class="hide">
        <video autoplay="" width="400" height="300" id="display-media-player"></video>
        <img src="" alt="" width="640" height="400" id="display-media-screen">
      </div>
    </div>

    <el-dialog
        :visible="dialogHighNoise"
        width="400px"
        class="modal-dialog-exit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <template #title>
        <svg width="69" height="53" viewBox="0 0 69 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="7.7207" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="7.7207" y="38.6992" width="6.12467" height="14.0386" rx="3.06234" fill="#E24C4B"/>
          <rect x="15.4414" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="62" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="15.4414" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
          <rect x="62" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
          <rect x="23.1602" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="23.1602" y="24.6758" width="6.12467" height="28.0631" rx="3.06234" fill="#E24C4B"/>
          <rect x="30.7012" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="30.7012" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="38.5098" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="38.5098" y="28.1152" width="6.12467" height="24.6231" rx="3.06234" fill="#E24C4B"/>
          <rect x="46.1426" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="54" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
          <rect x="46.1426" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
          <rect x="54" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_9')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_10')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
        <button class="modal-btn-continue" @click="dialogHighNoise=false" :disabled="!isQuiet">
          {{$t('modal_5')}}
        </button>
      </div>

    </el-dialog>
    <el-dialog
        :visible="!getAudio && $route.name !== 'rules'"
        width="400px"
        class="modal-dialog-exit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
          <path d="M25.5 26C27.433 26 29 24.433 29 22.5C29 20.567 27.433 19 25.5 19C23.567 19 22 20.567 22 22.5C22 24.433 23.567 26 25.5 26Z" stroke="#FF5257" stroke-width="2" stroke-linejoin="round"/>
          <path d="M32.5 31.5L36.5 35.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M32.5 35.5L36.5 31.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.5 30H25.4C23.1598 30 22.0397 30 21.1841 30.436C20.4314 30.8195 19.8195 31.4314 19.436 32.184C19 33.0397 19 34.1598 19 36.4V37H29.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_16')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_17')}}
      </p>

      <div class="d-flex justify-content-between">
        <button class="modal-btn-test">
          {{$t('modal_4')}}
        </button>
        <!--        <button class="modal-btn-continue" @click="dialogNoFace=false" :disabled="!isFaceDetected">-->
        <!--          Продолжить-->
        <!--        </button>-->
      </div>

    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import captureVideoFrame from '../../public/captureVideoFrame';
import AWSS3UploadAshClient from "aws-s3-upload-ash";
import md5 from "md5";
import Draggable from './Draggable.vue';
import { socket } from "../api/ws";
import { EventBus } from "@/eventBus";
import { mapState } from "vuex";
import * as faceapi from "face-api.js";

let userMediaStreamObject = {};
let displayMediaStreamObject = {};
export default {
  name: 'Proctoring',
  components: {
    Draggable
  },
  data() {
    return {
      modalMicrophoneAccess: false,
      hasMicrophoneAccess: true,
      noiseMessage: '',
      isQuiet: false,
      dialogWantExit: false,
      dialogHighNoise: false,
      noiseLevel: 0,
      animationFrameId: null,
      microphone: null,
      bars: Array(10).fill({ height: 100, color: 'gray' }),
      audioContext: null,
      analyser: null,
      lowNoiseLevel : this.$store.state.proctoring.lowNoiseLevel,
      highNoiseLevel : this.$store.state.proctoring.highNoiseLevel,
      timer1: '',
      timer2: '',
      value2: 0,
      options: [
        {
          value: 0,
          label: '150*159',
        },
        {
          value: 10,
          label: '200*203',
        },
        {
          value: 20,
          label: '250*234',
        },
        {
          value: 30,
          label: '300*272',
        },
      ],
      saveInterruption: null,
      saveInterruptionFile: null,
      storedDescriptor: null,
    }
  },
  watch: {
    dialogHighNoise(newVal) {
      if (newVal) {
        if (window.location.pathname.includes("about-subject")) {
          this.dialogHighNoise = false;
          return;
        }
        this.sendRequestHighNoise();
      }
    },
    streamCamera(newStream) {
      this.requestCameraProc(newStream)
    },
    isQuiet(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          console.log("Шум снизился, обновляем кнопку");
        });
      }
    }
  },
  methods: {
    sendViolation(text) {
      const params = {
        "type": text
      }

      const token = localStorage.getItem('quiz-token')

      this.$http.post(API_ROOT + `/api/quiz/${token}/violation`, params)
          .then(res => {
            console.log('res', res);

            if (res.body.ended) {
              localStorage.removeItem('violationsNumber');
              localStorage.removeItem("savedPhoto")
              // window.location.href = "https://1000bala.elumiti.kz/testing"

              const quizData = JSON.parse(localStorage.getItem('quiz-data'))

              if(quizData.display_results) {
                window.location.href = this.$router.resolve({ name: 'test-results' }).href;
              } else {
                window.location.href = 'https://1000bala.elumiti.kz/testing'
              }
            }

            const newViolations = Number(res.body.remaining_violations);
            localStorage.setItem('violationsNumber', res.body.remaining_violations);
            this.updateViolations(newViolations);
          })
          .catch(error => {
            console.log(error)
          })
    },
    async catchInterruption(text) {
      try {
        const videoElement = document.createElement('video');
        videoElement.srcObject = this.userMediaStreamObject;
        await videoElement.play();

        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const blob = await new Promise(resolve =>
            canvas.toBlob(resolve, 'image/jpeg', 0.6) // Сжатие до 60% качества
        );

        this.saveInterruption(blob, 'user', text, text);
        this.sendViolation(text);
      } catch (error) {
        console.error("Ошибка при создании снимка веб-камеры:", error);
      }
    },
    sendRequestHighNoise() {
      console.log('sending request about noise')
    },
    updateWebCamSize(size) {
      this.$store.commit('updateWebcamSize', size);
    },
    updateAudio(status) {
      this.$store.commit('updatePermissionAudio', status);
    },
    updateWebCam(status) {
      this.$store.commit('updatePermissionWebCam', status);
    },
    updateWebContents(status) {
      this.$store.commit('updatePermissionWebContents', status);
    },
    updateViolations(num) {
      this.$store.commit('updateViolations', num);
      localStorage.setItem('violationsNumber', num);
    },

    getRandomNumber(normalized) {
      const numbers = [1, 3, 7, 9, 10];
      const randomIndex = Math.floor(Math.random() * numbers.length);

      if (normalized <= 0.3) {
        return numbers[randomIndex] * 3;
      } else if (normalized <= 0.7) {
        return numbers[randomIndex] * 10;
      } else {
        return numbers[randomIndex] * 12;
      }
    },
    requestCameraProc(mediaStream) {
      console.log('перезапускаем камеру на прокторинге')

      let updateWebCam = this.updateWebCam
      let save = this.saveInterruptionFile
      let sendViolation = this.sendViolation

      if (mediaStream) {
        mediaStream.getVideoTracks()[0].onended = function () {
          updateWebCam(false);

          if (location.pathname == "/identification" || location.pathname == "/about") {
            setTimeout(() => {
              location.reload();
            }, 1000);
          }

          if (location.pathname.includes('question')) {
            location.href = "https://1000bala.elumiti.kz/testing";
          }
        };

        this.updateWebCam(true);
        this.userMediaStreamObject = mediaStream;
        const videoPlayer = document.querySelector('video#video-player');
        const webcam = document.querySelector('#webcam');
        videoPlayer.srcObject = mediaStream;
        webcam.classList.remove("hidden");
        webcam.classList.add("active");
        // setInterval(() => this.captureAndCompare(mediaStream), 300000);

        const saveUserMedia = async function () {
          try {
            const videoElement = document.createElement('video');
            videoElement.srcObject = mediaStream;
            await videoElement.play();

            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            const blob = await new Promise(resolve =>
                canvas.toBlob(resolve, 'image/jpeg', 0.6) // Сжатие до 60% качества
            );

            save(blob, 'user', 'webcam', "planned");
          } catch (error) {
            console.error("Ошибка при создании снимка веб-камеры:", error);
          }
        };


        const catchInterruption = async function () {
          try {
            const videoElement = document.createElement('video');
            videoElement.srcObject = mediaStream;
            await videoElement.play();

            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            const blob = await new Promise(resolve =>
                canvas.toBlob(resolve, 'image/jpeg', 0.6) // Сжатие до 60% качества
            );

            save(blob, 'user', 'webcam', "tab_changed");
            sendViolation("tab_changed");
          } catch (error) {
            console.error("Ошибка при создании снимка веб-камеры:", error);
          }
        };


        let warningOnblur = this.$t('do_not_minimize_your_browser');
        let attentionTitle = this.$t('attention_title');

        window.onblur = function () {
          Vue.toastr({
            message: attentionTitle,
            description: warningOnblur,
            type: 'warning',
            duration: 10000
          });

          catchInterruption();
        };

        document.addEventListener("visibilitychange", function () {
          if (document.hidden) {
            catchInterruption();
          }
        });

        setTimeout(saveUserMedia, 5000);
        setInterval(saveUserMedia, 180000);
      } else {
        this.updateWebCam(false);

        // Останавливаем поток, если он был активен
        if (this.userMediaStreamObject) {
          this.userMediaStreamObject.getTracks().forEach(track => track.stop());
          this.userMediaStreamObject = null;
        }

        // Vue.toastr({
        //   message: this.$t('attentionTitle'),
        //   description: this.$t('access_to_webcam'),
        //   type: 'error',
        // });

        if (location.pathname == "/identification" || location.pathname == "/about") {
          setTimeout(() => {
            location.reload();
          }, 1000);
        }

        if (location.pathname.includes('question')) {
          location.href = "https://1000bala.elumiti.kz/testing";
        }
      }

    },
    async getDescriptorFromBase64(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = async () => {
          const detections = await faceapi.detectSingleFace(img)
              .withFaceLandmarks()
              .withFaceDescriptor();

          if (!detections) {
            console.warn(" Лицо не найдено на изображении!");
            return null;
          }
          resolve(detections.descriptor);
        };
        img.onerror = reject;
      });
    },
    async captureAndCompare(mediaStream) {
      // const video = this.$refs.videoElement3;

      const video = document.createElement('video');
      video.srcObject = mediaStream;
      await video.play();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const userDescriptor = await this.getDescriptorFromBase64(canvas.toDataURL());

      console.log('this.storedDescriptor', this.storedDescriptor)

      if (!userDescriptor) {
        console.log("Лицо пользователя не найдено!");
        return;
      }
      const distance = faceapi.euclideanDistance(userDescriptor, this.storedDescriptor);

      if (distance < 0.6) {
        console.log("Лица совпадают!");
      } else {
        console.log("Лица не совпадают!");
        await this.catchInterruption('face_match');
      }
    },
    async requestAccess() {
      let hasMicrophoneAccess = this.updateAudio

      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        stream.getAudioTracks()[0].onended = function () {

          hasMicrophoneAccess(false);


        };

        hasMicrophoneAccess(true);
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.microphone = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.microphone.connect(this.analyser);
        this.analyser.fftSize = 256;

        this.startVisualizing();
      } catch (error) {
        console.error('Ошибка доступа к микрофону:', error);

        Vue.toastr({
          message: 'Ошибка',
          description: 'Ошибка доступа к микрофону. Проверьте микрофон.',
          type: 'error'
        })

        hasMicrophoneAccess(false)
      }
    },

    startVisualizing() {
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

      const updateBars = () => {
        this.analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        const normalized = Math.min(average / 100, 1);

        this.noiseLevel = 20 * Math.log10(average / 255) + 90;

        let color = 'gray';
        if (this.noiseLevel <= this.lowNoiseLevel) { // Низкий уровень шума (Тихо)
          color = 'green';
          this.noiseMessage = '<span style="color: #018837;" class="noise-message">У вас низкий уровень шума</span>';
          this.isQuiet = true;

        } else if (this.noiseLevel > this.lowNoiseLevel && this.noiseLevel <= this.highNoiseLevel) { // Средний уровень шума (Шумно)
          color = 'yellow';
          this.noiseMessage = '<span style="color: #FAD059;;" class="noise-message">У вас средний уровень шума, обеспечьте тишину</span>';
          this.isQuiet = false;

          if (!this.dialogHighNoise && !window.location.pathname.includes("about-subject")) {
            this.dialogHighNoise = true;
            this.catchInterruption('noise_level');
          }

        } else if (this.noiseLevel > this.highNoiseLevel) { // Высокий уровень шума (Очень шумно)
          color = 'red';
          this.noiseMessage = '<span style="color: #E24C4B;" class="noise-message">У вас высокий уровень шума, обеспечьте тишину</span>';
          this.isQuiet = false;

          if (!this.dialogHighNoise && !window.location.pathname.includes("about-subject")) {
            this.dialogHighNoise = true;
            this.catchInterruption('noise_level');
          }
        }

        // this.bars = this.bars.map(() => ({
        //   height: Math.max(10, normalized * 100),
        //   color: color,
        // }));

        this.bars = this.bars.map(() => ({
          height: this.getRandomNumber(normalized),
          color: color,
        }));

        this.animationFrameId = requestAnimationFrame(updateBars);
      };

      updateBars();
    },

  },
  computed: {
    getAudio() {
      return this.$store.state.proctoring.permissionAudio;
    },
    ...mapState({
      streamCamera: state => state.proctoring?.streamCamera || null
    }),
    referenceImageBase64() {
      return this.$store.state.proctoring.photo; // Эталонное фото в Base64
    },
  },
  created() {
    EventBus.$on("customEvent", (msg) => {
      this.catchInterruption(msg);
    });
    EventBus.$on("customViolation", (msg) => {
      this.sendViolation(msg)
    });
  },
  mounted() {
    let serverDate;
    let updateWebCam = this.updateWebCam
    let updateWebContents = this.updateWebContents
    let sendViolation = this.sendViolation
    this.saveInterruption = save

    this.$http.get(API_ROOT + '/api/date')
        .then(res => {
          console.log('res', res)
          serverDate = new Date(res.body.date)
        })
        .catch( err => {
          console.log('err', err)
          serverDate = new Date()
        })

    // if (this.referenceImageBase64) {
    //   setTimeout(async () => {
    //     this.storedDescriptor = await this.getDescriptorFromBase64(this.referenceImageBase64);
    //     if (this.storedDescriptor) {
    //       console.log("Эталонное лицо сохранено");
    //     }
    //   },3000)
    //
    // }

    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring){
      if(quiz.proctoring == 1) {

        const mediaStream = this.$store.state.proctoring.streamCamera; // Получаем MediaStream из Vuex

        console.log('mediaStream', mediaStream)

        if (mediaStream) {
          mediaStream.getVideoTracks()[0].onended = function () {
            updateWebCam(false);

            if (location.pathname == "/identification" || location.pathname == "/about") {
              setTimeout(() => {
                location.reload();
              }, 1000);
            }

            if (location.pathname.includes('question')) {
              location.href = "https://1000bala.elumiti.kz/testing";
            }
          };

          this.updateWebCam(true);
          this.userMediaStreamObject = mediaStream;
          const videoPlayer = document.querySelector('video#video-player');
          const webcam = document.querySelector('#webcam');
          videoPlayer.srcObject = mediaStream;
          webcam.classList.remove("hidden");
          webcam.classList.add("active");

          // setInterval(() => this.captureAndCompare(mediaStream), 300000);

          const saveUserMedia = async function () {
            try {
              const videoElement = document.createElement('video');
              videoElement.srcObject = mediaStream;
              await videoElement.play();

              const canvas = document.createElement('canvas');
              canvas.width = videoElement.videoWidth;
              canvas.height = videoElement.videoHeight;
              const ctx = canvas.getContext('2d');

              ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

              const blob = await new Promise(resolve =>
                  canvas.toBlob(resolve, 'image/jpeg', 0.6) // Сжатие до 60% качества
              );

              save(blob, 'user', 'webcam', "planned");
            } catch (error) {
              console.error("Ошибка при создании снимка веб-камеры:", error);
            }
          };


          const catchInterruption = async function () {
            try {
              const videoElement = document.createElement('video');
              videoElement.srcObject = mediaStream;
              await videoElement.play();

              const canvas = document.createElement('canvas');
              canvas.width = videoElement.videoWidth;
              canvas.height = videoElement.videoHeight;
              const ctx = canvas.getContext('2d');

              ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

              const blob = await new Promise(resolve =>
                  canvas.toBlob(resolve, 'image/jpeg', 0.6) // Сжатие до 60% качества
              );

              save(blob, 'user', 'webcam', "tab_changed");
              sendViolation("tab_changed");
            } catch (error) {
              console.error("Ошибка при создании снимка веб-камеры:", error);
            }
          };


          let warningOnblur = this.$t('do_not_minimize_your_browser');
          let attentionTitle = this.$t('attention_title');

          window.onblur = function () {
            Vue.toastr({
              message: attentionTitle,
              description: warningOnblur,
              type: 'warning',
              duration: 10000
            });
            catchInterruption();
          };

          document.addEventListener("visibilitychange", function () {
            if (document.hidden) {
              catchInterruption();
            }
          });

          setTimeout(saveUserMedia, 5000);
          setInterval(saveUserMedia, 180000);
        } else {
          this.updateWebCam(false);

          // Останавливаем поток, если он был активен
          if (this.userMediaStreamObject) {
            this.userMediaStreamObject.getTracks().forEach(track => track.stop());
            this.userMediaStreamObject = null;
          }

          // Vue.toastr({
          //   message: this.$t('attentionTitle'),
          //   description: this.$t('access_to_webcam'),
          //   type: 'error',
          // });

          if (location.pathname == "/identification" || location.pathname == "/about") {
            setTimeout(() => {
              location.reload();
            }, 1000);
          }

          if (location.pathname.includes('question')) {
            location.href = "https://1000bala.elumiti.kz/testing";
          }
        }


        this.requestAccess()

        if (!navigator.mediaDevices?.getDisplayMedia) {
          Vue.toastr({
            message: this.$t('attention_title'),
            description: this.$t('method_not_supported'),
            type: 'error',
          });

          setTimeout(() => {
            location.href = "https://1000bala.elumiti.kz/testing";
          }, 1000);
        }

        navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: 'monitor'
          }
        })
            .then(mediaStream => {
              mediaStream.getVideoTracks()[0].onended = function () {
                // window.location.href = '/completed';
                // window.location.href = '/about';
                updateWebContents(false)

                console.log('monitor')

                if (location.pathname == "/identification" || location.pathname == "/about") {
                  setTimeout( () => {
                    location.reload()
                  }, 1000)
                }

                if (location.pathname.includes('question')) {
                  location.href = "https://1000bala.elumiti.kz/testing"
                }

              };

              if (mediaStream.getTracks()[0].getSettings().displaySurface != "monitor") {
                setTimeout( () => {
                  location.reload()
                }, 1000)
              }

              if (mediaStream.getTracks()[0].getSettings().displaySurface == "monitor") {
                this.updateWebContents(true)
              }

              this.displayMediaStreamObject = mediaStream;
              const displaySurface = mediaStream.getTracks()[0].getSettings().deviceId;

              if (displaySurface.substring(0, 12) === 'web-contents') {
                if (this.displayMediaStreamObject) {
                  this.displayMediaStreamObject.stop();
                }
                Vue.toastr({
                  message: this.$t('attention_title'),
                  description: this.$t('access_to_full_screen_recording'),
                  type: 'error'
                });
                // this.$router.push({ name: 'about' });
                // window.location.href = '/about'
                // window.location.href = '/identification';
                // document.getElementById('permissionDenied').classList.add('active');
                // document.getElementById('webcam').classList.remove('active');
                // document.getElementById('webcam').classList.add('hidden');
              }

              document.getElementById('display-media-player').srcObject = mediaStream;
              const saveDeviceMedia = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device','web-contents', "planned");
              }

              const catchInterruption = function (){
                const frame = captureVideoFrame('display-media-player', 'png');
                save(frame.blob, 'device', 'web-contents',"tab_changed");
                sendViolation("tab_changed");
              }

              let warningOnblur = this.$t('do_not_minimize_your_browser')
              let attentionTitle = this.$t('attention_title')

              window.onblur = function () {
                console.log('window.onblur', document.hidden)
                Vue.toastr({
                  message: attentionTitle,
                  description: warningOnblur,
                  type: 'warning',
                  duration: 10000
                });
                setTimeout(catchInterruption, 500);
                // if (document.hidden) {
                //   setTimeout(catchInterruption, 500);
                // }
              }

              let warningVisibility = this.$t('do_not_switch_to_other_tabs')

              document.addEventListener("visibilitychange", function () {
                console.log('visibilitychange')
                if (document.hidden) {
                  Vue.toastr({
                    message: attentionTitle,
                    description:  warningVisibility,
                    type: 'warning',
                    duration: 10000
                  });
                  setTimeout(catchInterruption, 500);
                }
              });

              setTimeout(saveDeviceMedia, 5000);
              setInterval(saveDeviceMedia, 120000);
            })
            .catch(error => {
              this.updateWebContents(false)
              console.log('error', error)
              if (this.displayMediaStreamObject) {
                this.displayMediaStreamObject.stop();
              }
              Vue.toastr({
                message: this.$t('attention_title'),
                description: this.$t('access_to_full_screen_recording'),
                type: 'error',
              });

              if (location.pathname == "/identification" || location.pathname == "/about") {
                setTimeout( () => {
                  location.reload()
                }, 1000)
              }

              if (location.pathname.includes('question')) {
                location.href = "https://1000bala.elumiti.kz/testing"
              }

              // this.$router.push({ name: 'about' });
              // window.location.href = '/about'
              // window.location.href = '/identification';
              // document.getElementById('permissionDenied').classList.add('active');
              // document.getElementById('webcam').classList.remove('active');
              // document.getElementById('webcam').classList.add('hidden');
              // console.log(error);

              if (error.name == "NotAllowedError") {
                setTimeout(() => location.reload(), 1000);
              }
            });
      }
    }

    this.saveInterruptionFile = save

    function save(blob, type, comment = '', interruption = '') {
      try {
        const file = new File([blob], 'msr-' + (new Date).toISOString().replace(/:|\./g, '-') + '.gif', {
          type: 'image/gif'
        });

        let formData = new FormData();
        let filePath = 'interval/';
        if (interruption) filePath = 'interruptions/';
        filePath += type + '/' + file.name;

        let token = localStorage.getItem('quiz-token') || '';

        formData.append('video-filename', filePath);
        formData.append('video-blob', file);
        formData.append('token', token);
        if (interruption) {
          formData.append('violation_type', 'tab_changed');
        }
        formData.append('comment', comment);

        const filename = new Date().toISOString() + '.gif';
        const userIIN = localStorage.getItem('userIIN');
        const userId = JSON.parse(localStorage.getItem('user_id'));
        const md5hash = md5(userIIN + userId);
        const date = serverDate.toISOString().split('T')[0];
        const pathAWS = `stage_1/${date}/${userIIN}_${userId}_${md5hash}`;

        console.log('save');

        const quiz = JSON.parse(localStorage.getItem('quiz'));
        const userQuizId = localStorage.getItem('user_quiz_id')


        const wsBody = {
          event: "report",
          payload: {
            user_id: userId,
            file: `${pathAWS}/${filename}`,
            type: interruption,
            comment,
            metadata: {
              quiz_id: quiz.id,
              user_quiz_id : userQuizId,
            }
          }
        };

        socket.send(JSON.stringify(wsBody));

        let awsClient = new AWSS3UploadAshClient({
          bucketName: "lithium-60",
          dirName: pathAWS,
          region: "us-east-1",
          accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
          secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
          s3Url: "https://lithium-60.object.pscloud.io",
        });

        awsClient.uploadFile(file, file.type, undefined, filename, undefined)
            .then(() => console.log('Файл успешно загружен в AWS'))
            .catch(error => {
              console.error('Ошибка при загрузке файла в AWS:', error);
              throw new Error('Ошибка при загрузке в AWS');
            });

      } catch (error) {
        console.log(error)
      }
    }


    // this.saveInterruption = save

    function makeXMLHttpRequest(url, data, callback) {
      let request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          callback();
        }
      };
      request.open('POST', url);
      request.send(data);
    }
  },
  beforeDestroy() {
    let quiz = JSON.parse(localStorage.getItem('quiz'));
    if (quiz && quiz.proctoring) {
      if (quiz.proctoring == 1) {
        if (this.displayMediaStreamObject) {
          this.displayMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
        if (this.userMediaStreamObject) {
          this.userMediaStreamObject.getTracks().forEach(element => {
            element.stop()
          });
        }
      }
    }

    socket.close();
  }
}
</script>
